import environment from '../../config/environment.json';
import { Notify } from './notify';
import { autoinject } from 'aurelia-framework';

/// GENERATED BEGIN ///
export interface Actor {
	id: number;
	createTime: Date;
	createActorId?: number;
	modifyTime: Date;
	modifyActorId?: number;
	deleteTime?: Date;
	deleteActorId?: number;
	actorType: ActorType;
	username: string;
	companyName: string;
	businessId: string;
	canSftpLogin: boolean;
	canWebLogin: boolean;
	sftpIpRestriction?: string;
	sftpPublicKey?: string;
	errorContact?: string;
	gpgPublicKey?: string;
	x509Certificate?: string;
	companyNumberRestriction: number[];
}

export interface ActorAdminByIdInternalRequest {
	id: number;
}

export interface ActorAdminListInternalRequest {
	customerNumber: number[];
}

export interface ActorAdminListResponse {
	id: number;
	username: string;
	actorType: ActorType;
	companyName: string;
	businessId: string;
	canSftpLogin: boolean;
	canWebLogin: boolean;
	createActor: string;
	createTime: Date;
	modifyActor: string;
	modifyTime: Date;
}

export interface ActorAdminUpdateRequest {
	id?: number;
	actorType: ActorType;
	username: string;
	password?: string;
	companyName: string;
	businessId: string;
	canSftpLogin: boolean;
	canWebLogin: boolean;
	companyNumberRestriction: number[];
	sftpIpRestriction?: string;
	sftpPublicKey?: string;
	gpgPrivateKey?: string;
	createNewGpgPrivateKey: boolean;
	gpgPublicKey?: string;
	x509Certificate?: string;
	errorContact?: string;
	delete?: boolean;
}

export interface ActorAdminUpdateResponse {
	gpgPublicKey?: string;
}

export interface ActorChangePasswordRequest {
	oldPassword: string;
	password: string;
}

export interface ActorEdenredByIdInternalRequest {
	id: number;
}

export interface ActorEdenredListInternalRequest {
}

export interface ActorEdenredListResponse {
	id: number;
	username: string;
	actorType: ActorType;
	companyName: string;
	businessId: string;
	canSftpLogin: boolean;
	canWebLogin: boolean;
	createActor: string;
	createTime: Date;
	modifyActor: string;
	modifyTime: Date;
}

export interface ActorEdenredUpdateRequest {
	id?: number;
	actorType: ActorType;
	username: string;
	password?: string;
	companyName: string;
	businessId: string;
	delete?: boolean;
}

export interface ActorHrCsByIdInternalRequest {
	id: number;
}

export interface ActorHrCsListInternalRequest {
}

export interface ActorHrCsUpdateRequest {
	id?: number;
	companyName: string;
	refreshPasswordCustomer: boolean;
	refreshPasswordTow: boolean;
	businessId: string;
	companyNumberRestriction: number;
	sftpIpRestriction?: string;
	sftpPublicKey?: string;
	errorContact?: string;
	delete: boolean;
}

export interface ActorHrCsUpdateResponse {
	customerUsername?: string;
	customerPassword?: string;
	towUsername?: string;
	towPassword?: string;
}

export interface ActorPayrollCsByIdInternalRequest {
	id: number;
}

export interface ActorPayrollCsListInternalRequest {
}

export interface ActorPayrollCsUpdateRequest {
	id?: number;
	companyName: string;
	refreshPassword: boolean;
	businessId: string;
	companyNumberRestriction: number;
	sftpIpRestriction?: string;
	sftpPublicKey?: string;
	errorContact?: string;
	payrollDeductionRateLunch?: number;
	payrollDeductionRateRecreational?: number;
	payrollDeductionRateOther?: number;
	payrollWageTypeLunch?: number;
	payrollWageTypeRecreational?: number;
	payrollWageTypeOther?: number;
	delete: boolean;
}

export interface ActorPayrollCsUpdateResponse {
	username: string;
	password: string;
}

export type ActorType = "ADMIN" | "EDENRED_ADMIN" | "CUSTOMER_SERVICE" | "EDENRED_ARE_DOWNLOAD" | "EDENRED_YIT_UPLOAD" | "EDENRED_YIT_DOWNLOAD" | "EDENRED_RAMBOLL_DOWNLOAD" | "EDENRED_PWC_UPLOAD" | "EDENRED_PWC_DOWNLOAD" | "EDENRED_PKL_DOWNLOAD" | "EDENRED_INTRUM_UPLOAD" | "EDENRED_LAHTI_ULDL" | "EDENRED_SOFIGATE_DOWNLOAD" | "EDENRED_ESPERI_UPLOAD" | "EDENRED_HR_UPLOAD" | "EDENRED_HR_DOWNLOAD" | "EDENRED_PAYROLL_UPLOAD" | "EDENRED_PAYROLL_DOWNLOAD";

export type AttachmentType = "GENERIC_DOCUMENTATION" | "BEL_CUSTOM_INTEGRATION" | "SPECIFICATION";

export type CustomerType = "PP" | "PBU";

export interface Documentation {
	id: number;
	createTime: Date;
	createActorId?: number;
	modifyTime: Date;
	modifyActorId?: number;
	deleteTime?: Date;
	deleteActorId?: number;
	customerType: CustomerType;
	hr: IntegrationType;
	payroll: IntegrationType;
	customerNumber: number[];
	customerName: string;
	genericDocumentationId?: number;
	genericDocumentationSent?: Date;
}

export interface DocumentationAttachment {
	id: number;
	createTime: Date;
	createActorId: number;
	deleteTime?: Date;
	deleteActorId?: number;
	documentationId?: number;
	attachmentType: AttachmentType;
	fileName: string;
	mimeType: string;
	data: string;
}

export interface DocumentationAttachmentByIdInternalRequest {
	id: number;
}

export interface DocumentationAttachmentDeleteRequest {
	id: number;
}

export interface DocumentationAttachmentDownloadInternalRequest {
	id: number;
}

export interface DocumentationAttachmentListInternalRequest {
	documentationId?: number;
}

export interface DocumentationAttachmentUpdateRequest {
	documentationId?: number;
	attachmentType: AttachmentType;
	fileName: string;
	mimeType: string;
	data: string;
}

export interface DocumentationByIdInternalRequest {
	id: number;
}

export interface DocumentationListInternalRequest {
}

export interface DocumentationUpdateRequest {
	id?: number;
	customerType: CustomerType;
	hr: IntegrationType;
	payroll: IntegrationType;
	customerNumber: number[];
	customerName: string;
	genericDocumentationId?: number;
	genericDocumentationSent?: Date;
	delete: boolean;
}

export interface EventErrorList {
	createTime: Date;
	eventType: EventType;
	username: string;
	actorType: ActorType;
	description?: { [key: string]: any };
}

export interface EventLog {
	id: number;
	createTime: Date;
	eventSource: EventSource;
	eventType: EventType;
	remoteAddress?: string;
	webSessionId?: string;
	sshSessionId?: string;
	actorId?: number;
	description: { [key: string]: any };
}

export interface EventLogErrorListInternalRequest {
}

export interface EventLogListInternalRequest {
}

export interface EventLogWithActor extends EventLog {
	username: string;
}

export type EventSource = "WEB" | "SSH" | "INTEGRATION";

export type EventType = "LOGIN_FAILED" | "LOGIN_SUCCESS" | "LOGOUT" | "NEW_FILE" | "OPEN_FILE_FAILED" | "OPEN_FILE_SUCCESS" | "SSH_SESSION_CLOSED" | "SSH_SESSION_CREATED" | "SSH_SESSION_EVENT" | "SSH_SESSION_EXCEPTION" | "DATA_SUCCESS" | "DATA_ERROR" | "PAYROLL_DOWNLOAD" | "PAYROLL2_DOWNLOAD";

export interface HR {
	id: number;
	createTime: Date;
	createActorId: number;
	exportTime?: Date;
	fileName: string;
	companyNumber: number;
}

export interface HRRow {
	id: number;
	hrId: number;
	personalNumber?: string;
	firstName?: string;
	lastName?: string;
	homeAddress?: string;
	zip?: string;
	city?: string;
	mobileNumber?: string;
	email?: string;
	employeeNumber?: string;
	costCenter?: string;
	department?: string;
	floor?: string;
	region?: string;
	typeOfEmployment?: TypeOfEmployment;
	language?: HrLanguage;
	employmentStartDate?: Date;
	employmentEndDate?: Date;
	lunchBenefit?: boolean;
	recreationalBenefit?: boolean;
	transportBenefit?: boolean;
	massageBenefit?: boolean;
	dentalCareBenefit?: boolean;
}

export interface HrCsByIdResponse {
	customerActor: Actor;
	towActor: Actor;
}

export interface HrCsvInternalRequest {
	id?: number;
}

export type HrLanguage = "FI" | "EN";

export interface HrSearchInternalRequest {
	companyNumber?: number;
	date?: Date;
	tri2PersonalNumber?: string;
}

export interface HrViewInternalRequest {
	id?: number;
}

export type IntegrationType = "NONE" | "STANDARD" | "CUSTOM";

export interface LoginByUsernameRequest {
	username: string;
	password: string;
}

export type OrderLineStatus = "P" | "N";

export type OrderLineType = "L" | "N" | "W" | "c" | "R" | "U";

export type OrderType = "T" | "U" | "L" | "C";

export interface Payroll {
	id: number;
	createTime: Date;
	createActorId: number;
	fileName: string;
	companyNumber: number;
	companyName: string;
	fromDate: Date;
	toDate: Date;
}

export interface PayrollCsByIdResult {
	actor: Actor;
	settings: PayrollSettings;
}

export interface PayrollExcelInternalRequest {
	id: number;
}

export interface PayrollJsonInternalRequest {
	id: number;
}

export interface PayrollJsonv2InternalRequest {
	id: number;
}

export interface PayrollManualRequest {
	companyNumber: number;
	companyName: string;
	poi: PayrollOrderItem;
	invoiceNumber?: string;
	invoiceDate?: Date;
}

export interface PayrollOrderItem {
	id: number;
	payrollId: number;
	wageType?: number;
	deductionRate?: number;
	deduction?: number;
	accountOpened?: Date;
	accountClosed?: Date;
	towAddress?: string;
	towBalanceTMB?: number;
	towBalanceTR?: number;
	towBalanceTT?: number;
	towBalanceTMA?: number;
	towBalanceTDC?: number;
	towCardExpiryDateTD?: Date;
	towCardExpiryDateTMB?: Date;
	towCardExpiryDateTR?: Date;
	towCardExpiryDateTT?: Date;
	towCardExpiryDateTMA?: Date;
	towCardExpiryDateTDC?: Date;
	towCity?: string;
	towCostCenter?: string;
	towDepartment?: string;
	towEdenredCustomerNumber?: number;
	towEmail?: string;
	towEmployeeNumber?: string;
	towFirstName?: string;
	towFloor?: string;
	towItemCreatedWhen?: Date;
	towLastName?: string;
	towRegion?: string;
	towRepeatingActionTMB?: string;
	towRepeatingActionTR?: string;
	towRepeatingActionTT?: string;
	towRepeatingActionTMA?: string;
	towRepeatingActionTDC?: string;
	towScheduledDroppingDate?: Date;
	towSocialSecurityNumber?: string;
	towStatusTD?: number;
	towStatusTMB?: number;
	towStatusTR?: number;
	towStatusTT?: number;
	towStatusTMA?: number;
	towStatusTDC?: number;
	towTotalLoadTMB?: number;
	towTotalLoadTR?: number;
	towTotalLoadTT?: number;
	towTotalLoadTMA?: number;
	towTotalLoadTDC?: number;
	towTypeOfEmployment?: TypeOfEmployment;
	towUsedAmountTotalTMB?: number;
	towUsedAmountTotalTR?: number;
	towUsedAmountTotalTT?: number;
	towUsedAmountTotalTMA?: number;
	towUsedAmountTotalTDC?: number;
	towUserPhone?: string;
	towZipCode?: string;
	tri2LoadAmount?: number;
	tri2OrderLineStatus?: OrderLineStatus;
	tri2OrderLineType?: OrderLineType;
	tri2OrderInputDate?: Date;
	tri2OrderLoadDate?: Date;
	tri2ProductName?: string;
	tri2OrderType?: OrderType;
	tri2OrderNumber?: number;
	tri2WalletName?: WalletName;
	tri2StatusCode?: StatusCode;
}

export interface PayrollOrderItemV2 {
	wageType?: number;
	deductionRate?: number;
	deduction?: number;
	costCenter: string;
	department: string;
	edenredCustomerNumber: number;
	employeeNumber: string;
	firstName: string;
	floor: string;
	lastName: string;
	region: string;
	socialSecurityNumber: string;
	typeOfEmployment: TypeOfEmployment;
	benefitAmount: number;
	orderInputDate: Date;
	orderLoadDate: Date;
	productName: string;
	orderNumber: number;
	walletName: WalletName;
	invoiceNumber: string;
	invoiceDate: Date;
	accountOpened: Date;
	accountClosed: Date;
}

export interface PayrollOrderItemVersion1 {
	wageType?: number;
	deductionRate?: number;
	deduction?: number;
	["tri2.AccountOpened"]: Date;
	["tri2.AccountClosed"]: Date;
	["tow.Address"]: string;
	["tow.BalanceTMB"]: number;
	["tow.BalanceTR"]: number;
	["tow.BalanceTT"]: number;
	["tow.BalanceTMA"]: number;
	["tow.BalanceTDC"]: number;
	["tow.CardExpiryDateTD"]: Date;
	["tow.CardExpiryDateTMB"]: Date;
	["tow.CardExpiryDateTR"]: Date;
	["tow.CardExpiryDateTT"]: Date;
	["tow.CardExpiryDateTMA"]: Date;
	["tow.CardExpiryDateTDC"]: Date;
	["tow.City"]: string;
	["tow.CostCenter"]: string;
	["tow.Department"]: string;
	["tow.EdenredCustomerNumber"]: number;
	["tow.Email"]: string;
	["tow.EmployeeNumber"]: string;
	["tow.FirstName"]: string;
	["tow.Floor"]: string;
	["tow.ItemCreatedWhen"]: Date;
	["tow.LastName"]: string;
	["tow.Region"]: string;
	["tow.SocialSecurityNumber"]: string;
	["tow.RepeatingActionTMB"]: string;
	["tow.RepeatingActionTR"]: string;
	["tow.RepeatingActionTT"]: string;
	["tow.RepeatingActionTMA"]: string;
	["tow.RepeatingActionTDC"]: string;
	["tow.ScheduledDroppingDate"]: Date;
	["tow.StatusTD"]: string;
	["tow.StatusTMB"]: string;
	["tow.StatusTR"]: string;
	["tow.StatusTT"]: string;
	["tow.StatusTMA"]: string;
	["tow.StatusTDC"]: string;
	["tow.TotalLoadTMB"]: number;
	["tow.TotalLoadTR"]: number;
	["tow.TotalLoadTT"]: number;
	["tow.TotalLoadTMA"]: number;
	["tow.TotalLoadTDC"]: number;
	["tow.UsedAmountTotalTMB"]: number;
	["tow.UsedAmountTotalTR"]: number;
	["tow.UsedAmountTotalTT"]: number;
	["tow.UsedAmountTotalTMA"]: number;
	["tow.UsedAmountTotalTDC"]: number;
	["tow.UserPhone"]: string;
	["tow.ZipCode"]: string;
	["tri2.LoadAmount"]: number;
	["tri2.OrderInputDate"]: Date;
	["tri2.OrderLineStatus"]: OrderLineStatus;
	["tri2.OrderLineType"]: OrderLineType;
	["tri2.OrderLoadDate"]: Date;
	["tri2.OrderType"]: OrderType;
	["tri2.ProductName"]: string;
	["tri2.TRI2OrderNumber"]: number;
	["tri2.WalletName"]: WalletName;
	["tri2.StatusCode"]: StatusCode;
}

export interface PayrollSearchInternalRequest {
	companyName?: string;
	companyNumber?: number;
	dateStart?: Date;
	dateEnd?: Date;
	personalNumber?: string;
}

export interface PayrollSettings {
	companyNumber: number;
	payrollDeductionRateLunch?: number;
	payrollDeductionRateRecreational?: number;
	payrollDeductionRateOther?: number;
	payrollWageTypeLunch?: number;
	payrollWageTypeRecreational?: number;
	payrollWageTypeOther?: number;
}

export interface PayrollSettingsListInternalRequest {
}

export interface PayrollSettingsRequest {
	companyNumber: number;
	payrollDeductionRateLunch?: number;
	payrollDeductionRateRecreational?: number;
	payrollDeductionRateOther?: number;
	payrollWageTypeLunch?: number;
	payrollWageTypeRecreational?: number;
	payrollWageTypeOther?: number;
	delete: boolean;
}

export interface PayrollUploadRequest {
	name: string;
	data: string;
}

export interface Session {
	actorType: ActorType;
}

export interface SessionLogoutRequest {
	ignored?: string;
}

export interface SessionStatusInternalRequest {
}

export interface SessionStatusResponse {
	abbreviatedSha: string;
	ping: boolean;
}

export type StatusCode = "Cancelled" | "Entered" | "Validated" | "Produced" | "Invoiced" | "PartiallyProduced" | "Paid";

export type TypeOfEmployment = "F" | "P" | "T" | "I" | "H" | "D";

export type WalletName = "TR" | "TMB";

// Discovered object keys that should be read as a Date type
const dates: { [key: string]: true } = {
	"accountClosed": true,
	"accountOpened": true,
	"createTime": true,
	"date": true,
	"dateEnd": true,
	"dateStart": true,
	"deleteTime": true,
	"employmentEndDate": true,
	"employmentStartDate": true,
	"exportTime": true,
	"fromDate": true,
	"genericDocumentationSent": true,
	"invoiceDate": true,
	"modifyTime": true,
	"orderInputDate": true,
	"orderLoadDate": true,
	"toDate": true,
	"tow.CardExpiryDateTD": true,
	"tow.CardExpiryDateTDC": true,
	"tow.CardExpiryDateTMA": true,
	"tow.CardExpiryDateTMB": true,
	"tow.CardExpiryDateTR": true,
	"tow.CardExpiryDateTT": true,
	"tow.ItemCreatedWhen": true,
	"tow.ScheduledDroppingDate": true,
	"towCardExpiryDateTD": true,
	"towCardExpiryDateTDC": true,
	"towCardExpiryDateTMA": true,
	"towCardExpiryDateTMB": true,
	"towCardExpiryDateTR": true,
	"towCardExpiryDateTT": true,
	"towItemCreatedWhen": true,
	"towScheduledDroppingDate": true,
	"tri2.AccountClosed": true,
	"tri2.AccountOpened": true,
	"tri2.OrderInputDate": true,
	"tri2.OrderLoadDate": true,
	"tri2OrderInputDate": true,
	"tri2OrderLoadDate": true,
};


@autoinject
export class MyHttpClient {
	// Methods from fi.bel.edenred.integration.api.ActorAPI
	actorAdminById(request: ActorAdminByIdInternalRequest) {
		return this.doGet<Actor>('Actor/adminById', request);
	}
	actorAdminByIdUrl(request: ActorAdminByIdInternalRequest) {
		return this.getUrl('Actor/adminById', request);
	}
	actorAdminList(request: ActorAdminListInternalRequest) {
		return this.doGet<ActorAdminListResponse[]>('Actor/adminList', request);
	}
	actorAdminListUrl(request: ActorAdminListInternalRequest) {
		return this.getUrl('Actor/adminList', request);
	}
	actorAdminUpdate(request: ActorAdminUpdateRequest) {
		return this.doPut<ActorAdminUpdateResponse>('Actor/adminUpdate', request);
	}
	actorChangePassword(request: ActorChangePasswordRequest) {
		return this.doPut<void>('Actor/changePassword', request);
	}
	actorEdenredById(request: ActorEdenredByIdInternalRequest) {
		return this.doGet<Actor>('Actor/edenredById', request);
	}
	actorEdenredByIdUrl(request: ActorEdenredByIdInternalRequest) {
		return this.getUrl('Actor/edenredById', request);
	}
	actorEdenredList(request: ActorEdenredListInternalRequest) {
		return this.doGet<ActorEdenredListResponse[]>('Actor/edenredList', request);
	}
	actorEdenredListUrl(request: ActorEdenredListInternalRequest) {
		return this.getUrl('Actor/edenredList', request);
	}
	actorEdenredUpdate(request: ActorEdenredUpdateRequest) {
		return this.doPut<Actor>('Actor/edenredUpdate', request);
	}
	actorHrCsById(request: ActorHrCsByIdInternalRequest) {
		return this.doGet<HrCsByIdResponse>('Actor/hrCsById', request);
	}
	actorHrCsByIdUrl(request: ActorHrCsByIdInternalRequest) {
		return this.getUrl('Actor/hrCsById', request);
	}
	actorHrCsList(request: ActorHrCsListInternalRequest) {
		return this.doGet<Actor[]>('Actor/hrCsList', request);
	}
	actorHrCsListUrl(request: ActorHrCsListInternalRequest) {
		return this.getUrl('Actor/hrCsList', request);
	}
	actorHrCsUpdate(request: ActorHrCsUpdateRequest) {
		return this.doPut<ActorHrCsUpdateResponse>('Actor/hrCsUpdate', request);
	}
	actorPayrollCsById(request: ActorPayrollCsByIdInternalRequest) {
		return this.doGet<PayrollCsByIdResult>('Actor/payrollCsById', request);
	}
	actorPayrollCsByIdUrl(request: ActorPayrollCsByIdInternalRequest) {
		return this.getUrl('Actor/payrollCsById', request);
	}
	actorPayrollCsList(request: ActorPayrollCsListInternalRequest) {
		return this.doGet<Actor[]>('Actor/payrollCsList', request);
	}
	actorPayrollCsListUrl(request: ActorPayrollCsListInternalRequest) {
		return this.getUrl('Actor/payrollCsList', request);
	}
	actorPayrollCsUpdate(request: ActorPayrollCsUpdateRequest) {
		return this.doPut<ActorPayrollCsUpdateResponse>('Actor/payrollCsUpdate', request);
	}

	// Methods from fi.bel.edenred.integration.api.DocumentationAPI
	documentationAttachmentById(request: DocumentationAttachmentByIdInternalRequest) {
		return this.doGet<DocumentationAttachment>('Documentation/attachmentById', request);
	}
	documentationAttachmentByIdUrl(request: DocumentationAttachmentByIdInternalRequest) {
		return this.getUrl('Documentation/attachmentById', request);
	}
	documentationAttachmentDownload(request: DocumentationAttachmentDownloadInternalRequest) {
		return this.doGet<unknown>('Documentation/attachmentDownload', request);
	}
	documentationAttachmentDownloadUrl(request: DocumentationAttachmentDownloadInternalRequest) {
		return this.getUrl('Documentation/attachmentDownload', request);
	}
	documentationAttachmentList(request: DocumentationAttachmentListInternalRequest) {
		return this.doGet<DocumentationAttachment[]>('Documentation/attachmentList', request);
	}
	documentationAttachmentListUrl(request: DocumentationAttachmentListInternalRequest) {
		return this.getUrl('Documentation/attachmentList', request);
	}
	documentationAttachmentDelete(request: DocumentationAttachmentDeleteRequest) {
		return this.doPut<void>('Documentation/attachmentDelete', request);
	}
	documentationAttachmentUpload(request: DocumentationAttachmentUpdateRequest) {
		return this.doPut<void>('Documentation/attachmentUpload', request);
	}
	documentationById(request: DocumentationByIdInternalRequest) {
		return this.doGet<Documentation>('Documentation/byId', request);
	}
	documentationByIdUrl(request: DocumentationByIdInternalRequest) {
		return this.getUrl('Documentation/byId', request);
	}
	documentationList(request: DocumentationListInternalRequest) {
		return this.doGet<Documentation[]>('Documentation/list', request);
	}
	documentationListUrl(request: DocumentationListInternalRequest) {
		return this.getUrl('Documentation/list', request);
	}
	documentationUpdate(request: DocumentationUpdateRequest) {
		return this.doPut<Documentation>('Documentation/update', request);
	}

	// Methods from fi.bel.edenred.integration.api.EventLogAPI
	eventLogErrorList(request: EventLogErrorListInternalRequest) {
		return this.doGet<EventErrorList[]>('EventLog/errorList', request);
	}
	eventLogErrorListUrl(request: EventLogErrorListInternalRequest) {
		return this.getUrl('EventLog/errorList', request);
	}
	eventLogList(request: EventLogListInternalRequest) {
		return this.doGet<EventLogWithActor[]>('EventLog/list', request);
	}
	eventLogListUrl(request: EventLogListInternalRequest) {
		return this.getUrl('EventLog/list', request);
	}

	// Methods from fi.bel.edenred.integration.api.HrAPI
	hrCsv(request: HrCsvInternalRequest) {
		return this.doGet<string>('Hr/csv', request);
	}
	hrCsvUrl(request: HrCsvInternalRequest) {
		return this.getUrl('Hr/csv', request);
	}
	hrSearch(request: HrSearchInternalRequest) {
		return this.doGet<HR[]>('Hr/search', request);
	}
	hrSearchUrl(request: HrSearchInternalRequest) {
		return this.getUrl('Hr/search', request);
	}
	hrView(request: HrViewInternalRequest) {
		return this.doGet<HRRow[]>('Hr/view', request);
	}
	hrViewUrl(request: HrViewInternalRequest) {
		return this.getUrl('Hr/view', request);
	}

	// Methods from fi.bel.edenred.integration.api.PayrollAPI
	payrollExcel(request: PayrollExcelInternalRequest) {
		return this.doGet<unknown>('Payroll/excel', request);
	}
	payrollExcelUrl(request: PayrollExcelInternalRequest) {
		return this.getUrl('Payroll/excel', request);
	}
	payrollJson(request: PayrollJsonInternalRequest) {
		return this.doGet<PayrollOrderItemVersion1[]>('Payroll/json', request);
	}
	payrollJsonUrl(request: PayrollJsonInternalRequest) {
		return this.getUrl('Payroll/json', request);
	}
	payrollJsonv2(request: PayrollJsonv2InternalRequest) {
		return this.doGet<PayrollOrderItemV2[]>('Payroll/jsonv2', request);
	}
	payrollJsonv2Url(request: PayrollJsonv2InternalRequest) {
		return this.getUrl('Payroll/jsonv2', request);
	}
	payrollManual(request: PayrollManualRequest) {
		return this.doPut<void>('Payroll/manual', request);
	}
	payrollSearch(request: PayrollSearchInternalRequest) {
		return this.doGet<Payroll[]>('Payroll/search', request);
	}
	payrollSearchUrl(request: PayrollSearchInternalRequest) {
		return this.getUrl('Payroll/search', request);
	}
	payrollSettings(request: PayrollSettingsRequest) {
		return this.doPut<void>('Payroll/settings', request);
	}
	payrollSettingsList(request: PayrollSettingsListInternalRequest) {
		return this.doGet<PayrollSettings[]>('Payroll/settingsList', request);
	}
	payrollSettingsListUrl(request: PayrollSettingsListInternalRequest) {
		return this.getUrl('Payroll/settingsList', request);
	}
	payrollUploadJson(request: PayrollUploadRequest) {
		return this.doPut<void>('Payroll/uploadJson', request);
	}

	// Methods from fi.bel.edenred.integration.api.SessionAPI
	sessionLoginByUsername(request: LoginByUsernameRequest) {
		return this.doPut<Session>('Session/loginByUsername', request);
	}
	sessionLogout(request: SessionLogoutRequest) {
		return this.doPut<unknown>('Session/logout', request);
	}
	sessionStatus(request: SessionStatusInternalRequest) {
		return this.doGet<SessionStatusResponse>('Session/status', request);
	}
	sessionStatusUrl(request: SessionStatusInternalRequest) {
		return this.getUrl('Session/status', request);
	}
/// GENERATED END ///

	public session?: Session;

	constructor(private notify: Notify) {
		this.session = this.getCredentials();
	}

	static toHash(list: { id: number, name: string }[]) {
		let y: { [key: number]: { name: string } } = {};
		for (let x of list) {
			y[x.id] = x;
		}
		return y;
	}

	static toHashStr(list: { id: string, name: string }[]) {
		let y: { [key: string]: { name: string } } = {};
		for (let x of list) {
			y[x.id] = x;
		}
		return y;
	}

	/** Credentials cache */
	private getCredentials(): Session | undefined {
		let session = localStorage.getItem(environment.sessionKey + "-session");
		if (session) {
			return <Session>JSON.parse(session);
		} else {
			return undefined;
		}
	}

	public setCredentials(session: Session): void {
		localStorage.setItem(environment.sessionKey + "-session", JSON.stringify(session));
		this.session = session;
	}
	public clearCredentials(): void {
		localStorage.removeItem(environment.sessionKey + "-session");
		this.session = undefined;
	}

	/** HTTP request encoder */
	private encode(params?: { [key: string]: any }): string {
		if (!params) {
			params = {};
		}
		let body: string[] = [];
		for (let key in params) {
			let value: any = params[key];
			if (value === null || value === undefined) {
				continue;
			}
			if (!(value instanceof Array)) {
				value = [value];
			}

			for (let val of value) {
				let v = val instanceof Date ? val.toJSON() : val;
				body.push(encodeURIComponent(key) + "=" + encodeURIComponent(v));
			}
		}
		return body.join("&");
	}

	private async fetch<T>(url: string, init: RequestInit & { headers: string[][] }): Promise<T> {
		init.mode = "cors";
		init.credentials = "include";
		init.redirect = "follow";
		init.cache = "no-store";

		let request = new Request(url, init);
		console.log("->", new Request(url, init));
		this.notify.spinner(1);
		try {
			let response = await fetch(request);
			console.log("<-", response);
			if (response.status === 200) {
				let data = await response.text();
				this.notify.spinner(-1);
				return JSON.parse(data, this.parseDate);
			}
			if (response.status === 204) {
				this.notify.spinner(-1);
				// @ts-ignore When server is expected to return 204, T=void (no body). TS can't prove this, though.
				return;
			}
			throw response;
		}
		catch (response: any) {
			this.notify.spinner(-1);
			/* This code path must result in failed promise.
			 * If it is a 403, we render a prettier error.
			 * Otherwise, we give a more technical error. */
			if (response.status === 403 && response.json) {
				response.text().then((dataStr: string) => {
					let data = JSON.parse(dataStr, this.parseDate);
					this.notify.info(data.message, data.args);
				});
			} else if (response.status && response.text) {
				response.text().then((reason: any) => {
					this.notify.info("client." + response.status, { reason });
				});
			} else {
				this.notify.info("client.unknown", { response });
			}
			throw response;
		}
	}

	parseDate(key: string, value: any) {
		if (dates[key] && value) {
			return new Date(value);
		} else {
			return value;
		}
	}

	doGet<T>(url: string, params: { [key: string]: any }): Promise<T> {
		let fullUrl = this.getUrl(url, params);
		return this.fetch(fullUrl, {
			headers: [],
			method: "get",
		});
	}

	getUrl(url: string, params: { [key: string]: any }): string {
		let arg = this.encode(params);
		if (arg) {
			url += "?" + arg;
		}
		return environment.server + url;
	}

	doPost<T>(url: string, params: { [key: string]: any }): Promise<T> {
		let body = this.encode(params);
		return this.fetch<T>(environment.server + url, {
			headers: [
				["Content-Type", "application/x-www-form-urlencoded"],
			],
			method: "post",
			body,
		});
	}

	doPut<T>(url: string, params: { [key: string]: any }): Promise<T> {
		return this.fetch(environment.server + url, {
			headers: [
				["Content-Type", "application/json"],
			],
			method: "put",
			body: JSON.stringify(params),
		});
	}
}
