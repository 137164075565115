import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpClient, ActorAdminUpdateRequest, ActorAdminUpdateResponse } from 'utils/client';

@autoinject
export class ActorsBelEdit
 {
  private actor: ActorAdminUpdateRequest = {
    username: "", password: "",
    actorType: 'EDENRED_PAYROLL_DOWNLOAD',
    businessId: "",
    companyName: "",
    companyNumberRestriction: [],
    canSftpLogin: true,
    canWebLogin: false,
    createNewGpgPrivateKey: false,
  };
  private actorResult?: ActorAdminUpdateResponse;
  private password2?: string;

  private companyNumberRestriction = "";

  constructor(private readonly i18n: I18N, private readonly router: Router, private readonly backend: MyHttpClient) {
  }

  async activate(params: { id?: string, actorId?: string }) {
    let id = params.id || params.actorId;
    if (id) {
      const actor = await this.backend.actorAdminById({ id: parseInt(id), });
      this.actor = { ...actor, createNewGpgPrivateKey: false };
      this.companyNumberRestriction = this.actor.companyNumberRestriction.join(", ");
    }
  }

  async save(deleted: boolean) {
    if (this.actor.password && this.actor.password != this.password2) {
      return;
    }

    if (deleted && !confirm(this.i18n.tr("common.confirmDelete"))) {
      return;
    }

    let tmp = {
      ...this.actor,
      companyNumberRestriction: this.companyNumberRestriction.split(/\D+/).filter(cn => cn.length > 0).map(x => parseInt(x)),
      delete: deleted,
    };
    this.actorResult = await this.backend.actorAdminUpdate(tmp);
    if (!this.actorResult.gpgPublicKey) {
      this.router.navigateToRoute("actors/bel/list");
    }
  }
}
